import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import frTranslation from './locales/fr/translation.json';
import arTranslation from './locales/ar/translation.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources:{
      fr: {
        translation: frTranslation,
      },
      ar: {
        translation: arTranslation,
      },
    },
    lng: 'fr',
    fallbackLng: 'fr',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      prefix: 'talaba_lang',
      expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
    },
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'talaba_lang',
    },
  });


export default i18n;