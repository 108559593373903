import React from "react";
import { useInView } from "react-intersection-observer";
import styled, { css } from "styled-components";
import { publicUrl } from '../../constants/endpoints';
import { useTranslation } from "react-i18next";

const AnimatedItem = styled.div`
  ${({ $animationduration, $inview }) =>
    $inview === "true"
      ? css`
          animation: 1.5s ${$animationduration}s fadeInUp both;
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}
`;

const Faq = () => {
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const questions = [
    {
      question: t('first question'),
      response: t('first response')
    },
    {
      question: t('second question'),
      response: t('second response')
    },
    {
      question: t('third question'),
      response: t('third response')
    },
    {
      question: t('fourth question'),
      response: t('fourth response')
    }
  ]

  return (
    <div className="faq-area pd-top-60 pd-bottom-90 text-center" ref={ref} id="faq">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-7 col-md-11">
            <div className="section-title">
              <h6
                className={`sub-title double-line ${
                  inView ? "pricing-animate-1" : ""
                }`}
              >
                {t("FAQs")}
              </h6>
              <h2 className={`title ${inView ? "pricing-animate-2" : ""}`}>
                {t('frequently asked questions')}
              </h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7 mt-5 ">
            <div id="accordion" className="accordion-area">
              {questions.map((res, index) => (
                <AnimatedItem
                  className="card single-faq-inner"
                  key={res.id}
                  $animationduration={0.4 + index * 0.2}
                  $inview={inView ? "true" : "false"}
                >
                  <div className="card-header" id={`ff-${index}`}>
                    <h5 className="mb-0">
                      <button
                        className="btn-link text-center"
                        data-toggle="collapse"
                        data-target={`#f-${index}`}
                        aria-expanded="true"
                        aria-controls={`f-${index}`}
                      >
                        {res.question}
                        <i className="fa fa-eye" />
                      </button>
                    </h5>
                  </div>
                  <div
                    id={`f-${index}`}
                    className="collapse"
                    aria-labelledby={`ff-${index}`}
                    data-parent="#accordion"
                  >
                    <div className="card-body">{res.response} </div>
                  </div>
                </AnimatedItem>
              ))}
            </div>
          </div>
          <div className="col-lg-5">
            <div className={`about-area-inner ${inView ? "about-animate-left" : ""}`}>
              <img src={publicUrl + "assets/img/FAQs.gif"} alt="img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
