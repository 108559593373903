// index.js
import React from "react";
import ReactDOM from "react-dom/client";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { I18nextProvider } from 'react-i18next'; // Import I18nextProvider
import i18n from './i18n'; // Import the i18n instance
import store from "./Services/store";
import Home from "./pages/home";
import Navbar from "./components/global/navbar";
import Footer from "./components/global/footer";
import AboutPage from "./pages/about-page";
import PolicyPage from "./pages/policy";
import ProfessorsPage from "./pages/professors-page";
import CoursesPage from "./pages/courses-page";
import ServicesPage from "./pages/services-page";

const Root = () => {
  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <>
          <Navbar/>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about-us" element={<AboutPage />} />
            <Route exact path="/services" element={<ServicesPage />} />
            <Route exact path="/courses" element={<CoursesPage />} />
            <Route exact path="/professors" element={<ProfessorsPage />} />
            <Route exact path="/policy" element={<PolicyPage />} />
          </Routes>
          <Footer/>
        </>
      </I18nextProvider>
    </BrowserRouter>
  );
};

ReactDOM.createRoot(document.getElementById("talaba")).render(
  <Provider store={store}>
    <>
      <Root />
    </>
  </Provider>
);
