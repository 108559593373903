import Modal from '@mui/material/Modal';
import { IconButton, Typography, Box } from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Backdrop from '@mui/material/Backdrop';

const InfoModal = (props) => {
    return (
        <Modal
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            open={props.showModal}
            onClose={props.handleCloseModal}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
            backdrop: {
                timeout: 500,
            },
            }}
        >
            <Box sx={props.style}>
                {
                    props.i18n.language === 'ar'
                    ?
                    <>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={props.handleCloseModal}
                            sx={{
                            position: 'absolute',
                            top: '1px',
                            left: '10px',
                            }}
                        >
                            <HighlightOffIcon
                            sx={{
                                fontSize: '2.5rem',
                                color: '#881EC9',
                            }}
                            />
                        </IconButton>
                        <Typography id="modal-modal-title" className='text-right' variant="h4" component="h2" sx={props.titleStyle}>
                            {props.currentService.title}
                        </Typography>
                        <hr/>
                        <Typography id="modal-modal-description" className='text-right' variant="h5" sx={props.descriptionStyle}>
                            {props.currentService.description}
                        </Typography>
                    </>
                    : 
                    <>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={props.handleCloseModal}
                            sx={{
                            position: 'absolute',
                            top: '1px',
                            right: '10px',
                            }}
                        >
                            <HighlightOffIcon
                            sx={{
                                fontSize: '2.5rem',
                                color: '#881EC9',
                            }}
                            />
                        </IconButton>
                        <Typography id="modal-modal-title" variant="h4" component="h2" sx={props.titleStyle}>
                            {props.currentService.title}
                        </Typography>
                        <hr/>
                        <Typography id="modal-modal-description" variant="h5" sx={props.descriptionStyle}>
                            {props.currentService.description}
                        </Typography>
                    </>
                }
            </Box>
        </Modal>
    )
}

export default InfoModal;