import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

const ServicesPage = () => {
    const { t, i18n } = useTranslation();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const services = [
        {
        title: t('first service title'),
        description: t('first service description'),
        icon: "assets/img/intro/7.png",
        },
        {
        title: t('second service title'),
        description: t('second service description'),
        icon: "assets/img/intro/8.png",
        },
        {
        title: t('third service title'),
        description: t('third service description'),
        icon: "assets/img/intro/9.png",
        },
        {
        title: t('fourth service title'),
        description: t('fourth service description'),
        icon: "assets/img/intro/10.png",
        }
    ]

    useEffect(() => {
        const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    return <>
    <div className='pd-top-150'>
        <div className='container'>
            <div className='about-area-inner'>
                <div className='row'>
                    {
                        i18n.language === "ar"
                        ?
                        <>
                            <div className='text-right about-animate-right pb-0'>
                                <div className='about-inner-wrap pt-5 pt-lg-3 mt-5 mt-lg-0'>
                                    <div className='section mb-0'>
                                        { isMobile
                                            ? <h2 className='title'style={{ paddingLeft: "250px" }} >{t('our services')}</h2>
                                            : <>
                                                <h2 className='title'>{t('our services')}</h2>
                                                <h3 className='title py-2'>{t('our services description')}</h3>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                services.map((service, index) => {
                                    return <div key={index} className='col-lg-12 about-animate-right'>
                                        <div className='about-inner-wrap pt-5 pt-lg-3 mt-5 mt-lg-0'>
                                            <div className='section-title mb-0'>
                                                <div className='single-intro-inner style-icon-bg text-right' style={{ maxHeight: "300px"}}>
                                                    <h3 className='title pb-2'>{service.title}</h3>
                                                    <p className="details pb-5">
                                                        {service.description}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                } )
                            }
                        </>
                        :
                        <>
                            <div className='col-lg-12 about-animate-right pb-0'>
                                <div className='about-inner-wrap pt-5 pt-lg-3 mt-5 mt-lg-0'>
                                    <div className='section-title mb-0'>
                                        <h2 className='title'>{t('our services')}</h2>
                                        {
                                            isMobile
                                            ?
                                            null
                                            :
                                            <h3 className='title py-2'>{t('our services description')}</h3>
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                services.map((service, index) => {
                                    return <div key={index} className='col-lg-12 about-animate-right'>
                                        <div className='about-inner-wrap pt-lg-3 mt-lg-0'>
                                            <div className='section-title mb-0'>
                                                <div className='single-intro-inner style-icon-bg' style={{ maxHeight: "300px"}}>
                                                    <h3 className='title pb-2'>{service.title}</h3>
                                                    <p className="details">
                                                        {service.description}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                } )
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    </div>
    </>
}

export default ServicesPage

