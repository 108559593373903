import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useInView } from "react-intersection-observer";
import { publicUrl } from '../../constants/endpoints';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t, i18n } = useTranslation();
	const [ref, inView] = useInView({
		triggerOnce: true,
		threshold: 0.1,
	  });

  useEffect(() => {
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";
    document.body.appendChild(minscript);

    return () => {
      document.body.removeChild(minscript);
    };
  }, []);

  return (
    <section className="footer-area footer-area-2 bg-gray" id="footer" ref={ref}>
      <div className="footer-top">
        <div className="container">
          <div className={`row ${i18n.language === 'ar' ? "text-right" : "text-left"}`}>
            {
              i18n.language === 'ar' ?
              <>
                <div className={`col-lg-2 col-md-6 pt-0 ${
                  inView ? "pricing-animate-3" : ""
                  }`}>
                  <div className="widget widget_nav_menu container">
                    <h4 className="widget-title">{t('courses')}</h4>
                    <div className="go-top row">
                      <div className='col-lg-12'><Link to="">{t('economy')}</Link></div>
                      <div className='col-lg-12'><Link to="">{t('management')}</Link></div>
                      <div className='col-lg-12'><Link to="">{t('mathematics')}</Link></div>
                      <div className='col-lg-12'><Link to="">{t('other')}</Link></div>
                    </div>
                  </div>
                </div>
                <div className={`col-lg-3 col-md-6 ${
                  inView ? "pricing-animate-2" : ""
                    }`}>
                  <div className="widget widget_contact container">
                    <h4 className="widget-title">{t('contact us')}</h4>
                    <div className="details row">
                      <div className='col-lg-12'>{t('address')}<i className="fa fa-map-marker pl-2" /></div>
                      <div className='col-lg-12'>contact@talabatn.net<i className="fa fa-envelope pl-2" /></div>
                      <div className='col-lg-12'>+216 94 081 195<i className="fa fa-phone pl-2" /></div>
                    </div>
                  </div>
                </div>
                <div className={`col-lg-3 col-md-6 pb-5 ${
                  inView ? "pricing-animate-3" : ""
                  }`}>
                  <div className="widget widget_nav_menu container">
                    <h4 className="widget-title">{t('about the company')}</h4>
                    <div className="go-top row">
                      <div className='col-lg-12'><Link to="/">{t('home')}</Link></div>
                      <div className='col-lg-12'><Link to="/about-us">{t('about')}</Link></div>
                      <div className='col-lg-12'><Link to="/services">{t('services')}</Link></div>
                      <div className='col-lg-12'><Link to="/courses">{t('our content')}</Link></div>
                      <div className='col-lg-12'><Link to="/professors">{t('our professors')}</Link></div>
                      <div className='col-lg-12'><Link to="/policy">{t('conditions')}</Link></div>
                    </div>
                  </div>
                </div>
                <div className={`col-lg-4 col-md-6 pt-5 ${inView ? "pricing-animate-1" : ""}`}>
                  <div className="widget widget_about text-center">
                    <img src={publicUrl + "assets/img/talabaLogo.png"} alt="img" height={150} />
                    <div className="details">
                      <p>{t('footer description')}</p>
                      <ul className="social-media">
                        <li><a href="https://www.facebook.com/talabatunisie/"><i className="fa fa-facebook " /></a></li>
                        <li><a href="https://www.youtube.com/@arbi-kaffela-gestion"><i className="fa fa-youtube " /></a></li>
                        <li><a href="https://www.instagram.com/talaba_tn_/"><i className="fa fa-instagram " /></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </>
              :
              <>
                <div className={`col-lg-4 col-md-6 ${
                    inView ? "pricing-animate-1" : ""
                  }`}>
                <div className="widget widget_about text-center">
                  <img src={publicUrl + "assets/img/talabaLogo.png"} alt="img" height={150} />
                    <div className="details">
                      <p>{t('footer description')}</p>
                      <ul className="social-media">
                        <li><a href="https://www.facebook.com/talabatunisie/"><i className="fa fa-facebook " /></a></li>
                        <li><a href="https://www.youtube.com/@arbi-kaffela-gestion"><i className="fa fa-youtube " /></a></li>
                        <li><a href="https://www.instagram.com/talaba_tn_/"><i className="fa fa-instagram " /></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={`col-lg-3 col-md-6 ${
                  inView ? "pricing-animate-2" : ""
                    }`}>
                  <div className="widget widget_contact">
                    <h4 className="widget-title">{t('contact us')}</h4>
                    <ul className="details">
                      <li><i className="fa fa-map-marker" />{t('address')}</li>
                      <li><i className="fa fa-envelope" />contact@talabatn.net</li>
                      <li><i className="fa fa-phone" />+216 94 081 195</li>
                    </ul>
                  </div>
                </div>
                <div className={`col-lg-3 col-md-6 ${
                  inView ? "pricing-animate-3" : ""
                  }`}>
                  <div className="widget widget_nav_menu">
                    <h4 className="widget-title">{t('about the company')}</h4>
                    <ul className="go-top">
                      <li><Link to="/">{t('home')}</Link></li>
                      <li><Link to="/about-us">{t('about')}</Link></li>
                      <li><Link to="/services">{t('services')}</Link></li>
                      <li><Link to="/courses">{t('our content')}</Link></li>
                      <li><Link to="/professors">{t('our professors')}</Link></li>
                      <li><Link to="/policy">{t('conditions')}</Link></li>
                    </ul>
                  </div>
                </div>
                <div className={`col-lg-2 col-md-6 ${
                  inView ? "pricing-animate-3" : ""
                  }`}>
                  <div className="widget widget_nav_menu">
                    <h4 className="widget-title">{t('courses')}</h4>
                    <ul className="go-top">
                      <li><Link to="">{t('economy')}</Link></li>
                      <li><Link to="">{t('management')}</Link></li>
                      <li><Link to="">{t('mathematics')}</Link></li>
                      <li><Link to="">{t('other')}</Link></li>
                    </ul>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-5 align-self-center">
              <p className='text-white'>Copyright © {new Date().getFullYear()} Talaba TN Inc. All Rights Reserved</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
