import React from "react";
import Modal from '@mui/material/Modal';
import { IconButton, Typography, Box } from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Backdrop from '@mui/material/Backdrop';
import { useTranslation } from "react-i18next";

const VideoModal = (props) => {
  const { i18n } = useTranslation();
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: 900,
    bgcolor: "white",
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
  };

  const titleStyle = {
    fontSize: '2rem',
    fontWeight: 'lighter',
    marginBottom: '1rem',
    color: '#A95DA9'
  };

  const descriptionStyle = {
    fontSize: '1.2rem'
  };

  return (
    <Modal
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      open={props.showModal}
      onClose={props.handleCloseModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        {
          i18n.language === 'ar'
            ?
            <>
              <IconButton
                edge="start"
                color="inherit"
                onClick={props.handleCloseModal}
                sx={{
                  position: 'absolute',
                  top: '1px',
                  left: '10px',
                }}
              >
                <HighlightOffIcon
                  sx={{
                    fontSize: '2.5rem',
                    color: '#881EC9',
                  }}
                />
              </IconButton>
              <Typography id="modal-modal-title" className="text-right" variant="h4" component="h2" sx={titleStyle}>
                {props.modalHeadTitle}
              </Typography>
            </>
            :
            <>
            <IconButton
              edge="end"
              color="inherit"
              onClick={props.handleCloseModal}
              sx={{
                position: 'absolute',
                top: '1px',
                right: '10px',
              }}
            >
              <HighlightOffIcon
                sx={{
                  fontSize: '2.5rem',
                  color: '#881EC9',
                }}
              />
            </IconButton>
            <Typography id="modal-modal-title" variant="h4" component="h2" sx={titleStyle}>
              {props.modalHeadTitle}
            </Typography>
            </>
        }
        <hr />
        <Typography id="modal-modal-description" variant="h5" sx={descriptionStyle}>
          <iframe
            width={props.isMobile ? "300" : "900"}
            height={props.isMobile ? "200" : "470"}
            src={`https://www.youtube.com/embed/${props.youtubeVideoId}?autoplay=1`}
            title="Talba TN"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
        </Typography>
      </Box>
    </Modal>
  );
};

export default VideoModal;
