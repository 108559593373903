import { configureStore } from "@reduxjs/toolkit";
import Teacher_Slice from "./Teacher/Teacher_Slice";
import Files_Slice from "./Files/Files_Slice";
import languageReducer from "./languageSlice";

const store = configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
    }),
    reducer: {
        teachers: Teacher_Slice,
        files: Files_Slice,
        language: languageReducer,

    }
})

export default store