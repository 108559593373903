// src/store/languageSlice.js

import { createSlice ,createAsyncThunk} from '@reduxjs/toolkit';
import fr from "../config/fr.json"// Import the language JSON files
import ar from "../config/ar.json"
const languages = {
    fr: fr ,
    ar: ar 
  // Add other supported languages here
};
export const fetchTranslations = createAsyncThunk(
  'language/fetchTranslations',
  async (selectedLanguage) => {
    return languages[selectedLanguage];
  }
);
//console.log(languages['fr'])
const languageSlice = createSlice({
  name: 'language',
  initialState: {
    selectedLanguage: 'fr', // Default language
    translations: fr, // Default translations
    isLoading: false, // Loading state
    error: null, // Error state
  },
  reducers: {
    setLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
      state.translations = languages[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchTranslations.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchTranslations.fulfilled, (state, action) => {
      state.isLoading = false;
      state.translations = action.payload;
    })
    .addCase(fetchTranslations.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message; // Store the error message
    });
  },
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
