import React from "react";
import Hero from "../components/global/hero";
import Services from "../components/global/services";
import About from "../components/global/about";
import HowToRegister from "../components/global/how-to-register";
import Faq from '../components/global/faq';
import Testimonials from "../components/global/testimonials";
import Professors from "../components/global/professors";
import Courses from "../components/global/courses";
import Podcast from "../components/global/podcast";

const Home = () => {
  return (
    <React.Fragment>
      <Hero/>
      <About/>
      <Services/>
      <HowToRegister/>
      <Courses/>
      <Professors showTitle={true} />
      <Testimonials />
      <Podcast />
      <Faq/>
    </React.Fragment>
  );
};

export default Home;
