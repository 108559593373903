import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { publicUrl } from '../../constants/endpoints';
import { useTranslation } from "react-i18next";

const Professors = (props) => {
  const { t, i18n } = useTranslation();

  const professors = [
    {
      "name": t("Arbi Kaffela"),
      "img": "assets/img/professors/arbi_kaffela.jpg",
      "position": t("Professor of management")
    },
    {
      "name": t("Olfa Boufahja"),
      "img": "",
      "position": t("Professor of economics")
    },
    {
      "name": t("Wassim Berrhima"),
      "img": "assets/img/professors/wassim_berrhima.jpg",
      "position": t("Professor of mathematics")
    },
    {
      "name": t("Samira Boukorraa"),
      "img": "",
      "position": t("University professor of economics")
    },
    {
      "name": t("Riadh Dridi"),
      "img": "assets/img/professors/riadh_dridi.jpg",
      "position": t("History and geography professor")
    },
    {
      "name": t("Ahmed Belhaj"),
      "img": "assets/img/professors/ahmed_belhaj.jpg",
      "position": t("Computer science professor")
    },
    {
      "name": t("Bassem Khalil"),
      "img": "assets/img/professors/bassem_khalil.jpg",
      "position": t("Arabic professor")
    },
    {
      "name": t("Rabeb Hamemi"),
      "img": "assets/img/professors/rabeb_hamemi.jpg",
      "position": t("Philosophy professor")
    }
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	useEffect(() => {
		const handleResize = () => {
		setIsMobile(window.innerWidth <= 768);
		};

		window.addEventListener('resize', handleResize);

		return () => {
		window.removeEventListener('resize', handleResize);
		};
	}, []);

  return (
    <div className="team-area pd-top-60 pd-bottom-90 go-top">
      <div className="container">
        <div className="row">
          {
            i18n.language === "ar"
            ?
            <>
              <div className="col-xl-6 col-lg-7"/>
              <div className="col-xl-6 col-lg-7 text-right">
                {
                  props.showTitle && (
                    <div className="section-title">
                      <h6 className="sub-title left-line">{t('meet our team')}</h6>
                      <h2 className="title">{t('our professors')}</h2>
                    </div>
                  )
                }
              </div>
            </>
            :
            <div className="col-xl-6 col-lg-7">
              {
                props.showTitle && (
                  <div className="section-title">
                    <h6 className="sub-title right-line">{t('meet our team')}</h6>
                    <h2 className="title">{t('our professors')}</h2>
                  </div>
                )
              }
            </div>
          }
        </div>
        <div className="row justify-content-center">
        <Swiper
            slidesPerView={isMobile ? 1 : 3}
            autoplay={{
              delay: 2500
            }}
            pagination={{
              clickable: true,
            }}
            loop={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            {
              professors.map((professor, index) => (
                <SwiperSlide className="col-lg-4 col-md-4 px-3" key={index}>
                  <div className="single-team-inner">
                    <div className="thumb">
                      <img style={{ height: '550px', objectFit: 'cover', backgroundColor: "#EAE9E7"}} src={ professor.img !== "" ? publicUrl + professor.img : publicUrl + "assets/img/professors/anonymous.png"} alt="img" />
                    </div>
                    <div className="details text-center" style={{ height: "120px" }}> 
                      <h4>{professor.name}</h4>
                      <span>{professor.position}</span>
                    </div>  
                  </div>
                </SwiperSlide>
              ))
            }
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default Professors