import React, {useEffect, useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { publicUrl } from '../constants/endpoints';
import { RoughNotation } from 'react-rough-notation'
import VideoModal from '../components/section-components/video-modal';
import { useTranslation } from "react-i18next";

const CoursesPage = () => {
	const { t, i18n } = useTranslation();
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
	const courses = [
		{
			"name": t("Séance de révision théorie du consommateur: Microéconomie"),
			"img": "assets/img/courses/course1.jpg",
			"category": "Talaba",
			"videoId": "d1KbiCQieWU",
			"rating": "62",
		},
		{
			"name": t("Extrait d’une séance de formation algorithmiques et langage C"),
			"img": "assets/img/courses/course2.jpg",
			"category": "Talaba",
			"videoId": "RPz_XtS5J2s",
			"rating": "39",
		},
		{
			"name": t("Extrait d’une séance de révision sur le producteur: Microéconomie"),
			"img": "assets/img/courses/course3.jpg",
			"category": "Talaba",
			"videoId": "_7m4wwLQfrY",
			"rating": "41",
		},
		{
			"name": t("Séance de révision les coûts complets: Gestion"),
			"img": "assets/img/courses/course4.png",
			"category": "Talaba Junior",
			"videoId": "DFDntr2FQQw",
			"rating": "167",
		},
		{
			"name": t("Exercices méthodiques sur l'essai d'histoire"),
			"img": "assets/img/courses/course5.jpg",
			"category": "Talaba Junior",
			"videoId": "G72XZJPjKHI",
			"rating": "31",
		},
		{
			"name": t("Exercice de révision dérivabilité pour bac économie: Mathématiques"),
			"img": "assets/img/courses/course6.png",
			"category": "Talaba Junior",
			"videoId": "nAAiRL7_rSE",
			"rating": "122",
		},
		{
			"name": t("Correction exercice gestion prévisionnelle des stocks: Gestion"),
			"img": "assets/img/courses/course7.jpg",
			"category": "Talaba Junior",
			"videoId": "u3yxnI_dEHY",
			"rating": "64",
		},
		{
			"name": t("La pensée scientifique des Arabes (baccalauréat en économie et gestion)"),
			"img": "assets/img/courses/course8.jpg",
			"category": "Talaba Junior",
			"videoId": "NlI_8NODO40",
			"rating": "36",
		},
		{
			"name": t("Géographie des routes commerciales"),
			"img": "assets/img/courses/course9.jpg",
			"category": "Talaba Junior",
			"videoId": "CGxIDw96t9s",
			"rating": "16",
		}
	];
	const [categories, setCategories] = useState([])
	const [selectedCourse, setSelectedCourse] = useState({})
	const [showModal, setShowModal] = useState(false)

	const handleOpenModal = (course) => {
		setSelectedCourse(course);
		setShowModal(true);
	}
	const handleCloseModal = () => {
		setShowModal(false);
	}

	const getCategories = (courses) => {
		const allCategories = courses.map((course) => course.category);
		const uniqueCategories = [...new Set(allCategories)];
		setCategories(uniqueCategories);
	};	  


	useEffect(() => {
		const handleResize = () => {
		setIsMobile(window.innerWidth <= 768);
		};

		window.addEventListener('resize', handleResize);

		return () => {
		window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		getCategories(courses)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

    return <>
        <VideoModal isMobile={isMobile} showModal={showModal} handleCloseModal={handleCloseModal} modalHeadTitle={selectedCourse.name} youtubeVideoId={selectedCourse.videoId} />
        <div className='pd-top-150'>
            <div className='container'>
                <div className='about-area-inner'>
                    <div className='row'>
						{
							i18n.language === "ar"
							?
							<>
								<div className='col-lg-3'/>
								<div className='col-lg-9 text-right about-animate-right pb-0'>
									<div className='about-inner-wrap pt-5 pt-lg-3 mt-5 mt-lg-0'>
										<div className='section-title mb-0'>
											<h2 className='title pb-2'>{t('our content')}</h2>
											<h4 style={{ fontWeight: "normal" }} className='pb-2'>{t('content page description')}</h4>
										</div>
									</div>
								</div>
							</>
							:
							<div className='col-lg-12 about-animate-right pb-0'>
								<div className='about-inner-wrap pt-5 pt-lg-3 mt-5 mt-lg-0'>
									<div className='section-title mb-0'>
										<h2 className='title pb-2'>{t('our content')}</h2>
										<h4 style={{ fontWeight: "normal" }} className='pb-2'>{t('content page description')}</h4>
									</div>
								</div>
							</div>
						}
                    </div>
                    <div className="row text-center">
                        {
                            categories.map((category, index) => (
                                <div key={index} className='col-lg-12 about-animate-right pb-0'>
                                    <div className='about-inner-wrap pt-5 pt-lg-3 mt-5 mt-lg-0'>
                                        <div className='section-title mb-0'>
                                            <h3 style={{ fontWeight: "lighter" }} className='title text-center pb-2'>
												{' '}
												<RoughNotation
												animate="true"
												type="box"
												show={true}
												color="#9143DA"
												animationDelay={1000}
												animationDuration={2500}
												className="text-slate-200"
												>
												{category}&nbsp;
												</RoughNotation>
											</h3>
                                        </div>
                                    </div>
									<Swiper
										slidesPerView={isMobile ? 1 : Math.min(3, courses.filter((course) => course.category === category).length)}
										autoplay={{ delay: 2500 }}
										pagination={{ clickable: true }}
										modules={[Autoplay, Pagination, Navigation]}
										className="mySwiper"
									>
										{
											courses.filter((course) => course.category === category).map((course, index) => (
												<SwiperSlide
												className={`col-lg-${Math.max(3 / Math.min(3, courses.filter((course) => course.category === category).length), 4)} col-md-6`}
												style={{ cursor: "pointer" }}
												key={index}
												>
													<div className="single-course-inner bg-white" onClick={() => handleOpenModal(course)}>
														<div className="thumb">
															<img style={{ height: '200px', objectFit: 'cover'}} src={publicUrl+course.img} alt="img" />
														</div>
														<div className="details">
															<div className="details-inner" style={{ minHeight: "110px" }}>
																<h6>{course.name}</h6>
															</div>
															<div className="emt-course-meta">
																<div className="row">
																	<div className="col-3">
																		<div className="rating">
																			<i className="fa fa-thumbs-up" /> {course.rating}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>	
												</SwiperSlide>
											))
										}
									</Swiper>
                                </div>
                            ))
                        }
			    </div>
                </div>
            </div>
        </div>
    </>
}

export default CoursesPage

