import React, { useEffect, useState } from 'react';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import VideoModal from '../components/section-components/video-modal';
import { publicUrl } from '../constants/endpoints';
import { useTranslation } from 'react-i18next';

const AboutPage = () => {
    const { t, i18n } = useTranslation();
    const descriptionPartOne = t('about us description part one')
    const descriptionParttwo = t('about us description part two')
    const descriptionPartthree = t('about us description part three')
    const descriptionsList = [descriptionPartOne, descriptionParttwo, descriptionPartthree]
    const [showModal, setShowModal] = useState(false);
    const [height, setHeight] = useState("300px");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const handleOpenModal = () => {
        setShowModal(true);
    }
    const handleCloseModal = () => {
        setShowModal(false);
    }

    useEffect(() => {
        const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    useEffect(() => {
        if (i18n.language === "ar") {
            setHeight("200px")
        } else {
            setHeight("300px")
        }
    }, [i18n.language])
    return <>
        <VideoModal isMobile={isMobile} showModal={showModal} handleCloseModal={handleCloseModal} modalHeadTitle="Qu'est-ce que Talaba TN ?" youtubeVideoId="XgSIAyfVFo8" />
        <div className="about-area pd-top-150">
            <div className="container">
                <div className="about-area-inner">
                    <div className="row">
                        {
                            i18n.language === "ar"
                            ?
                            <>
                                <div className='col-lg-9'/>
                                <div className={`col-lg-3 about-animate-ight`}>
                                    <div className="about-inner-wrap pl-xl-4 pt-5 pt-lg-3 mt-5 mt-lg-0">
                                        <div className="section-title mb-0">
                                            <h2 className={isMobile ? "title pb-2 text-center" : "title pb-2"}>{t('about us question')}</h2>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <div className={`col-lg-12 about-animate-right`}>
                                <div className="about-inner-wrap pl-xl-4 pt-5 pt-lg-3 mt-5 mt-lg-0">
                                    <div className="section-title mb-0">
                                        <h2 className={isMobile ? "title pb-2 text-center" : "title pb-2"}>{t('about us question')}</h2>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-lg-12">
                            <div className="single-intro-inner style-icon-bg text-center">
                            <img
                                style={{ height: "auto", borderRadius: "10px" }}
                                src={publicUrl + "assets/img/about/video-cover-page.jpg"}
                                alt="img"
                            />
                            <div style={{ backgroundColor: "white", height: isMobile ? "50px" : "100px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", borderRadius: "50%"}}>
                                <span onClick={() => handleOpenModal()} style={{ color: "gold", fontWeight: "bold", display: "inline-block", animation: "bounce 1s infinite alternate" }}>
                                <PlayCircleIcon style={{ fontSize: isMobile ? "50px" : "100px", transition: "transform 0.3s ease-in-out", cursor: "pointer" }} />
                                </span>
                            </div>
                            </div>
                        </div>
                        {
                            descriptionsList.map((description, index) => {
                                return <div key={index} className={`col-lg-4 about-animate-right`}>
                                    <div className="about-inner-wrap mt-lg-0">
                                        <div className="section-title mb-0 text-center">
                                            <div className='single-intro-inner style-icon-bg' style={{ height: height}}>
                                                <p className="details">
                                                    {description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default AboutPage

