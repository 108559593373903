import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = { record: [], loading: 'idle', error: null }

export const fileHandler = createAsyncThunk(
    "files/fileHandler",
    async (data, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            const response = await axios.post("http://localhost/file_handler/save_course_file?file_type=free", data,
                {
                    headers: {
                        Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJ0YWxhYmEtaWFtIiwiYXVkIjoidGFsYWJhLWlhbSIsImlzcyI6Imh0dHBzOi8vaWFtLnRhbGFiYS5jb20iLCJleHAiOjE2ODY5MjM3MDYsImlhdCI6MTY2NTMyMzcwNiwiYWNjb3VudF92ZXJpZmllZCI6dHJ1ZSwidXNlcl9waG9uZV9udW1iZXIiOm51bGwsInVzZXJfZW1haWwiOiJtemFoaGRAZ21haWwuY29tIiwiZmlyc3RfbmFtZSI6ImhlZGkiLCJsYXN0X25hbWUiOiJtemFoIiwidXNlcl9pZCI6IjEiLCJhcHBsaWNhdGlvbnMiOlt7ImFwcGxpY2F0aW9uX2lkIjoidGFsYWJhX2FkbWluX2FwcCIsInBlcm1pc3Npb25zIjpbImFkbWluIiwibWFuYWdlciJdfV19.F6wU9ulUs5C4_x4p9s_r0O90XRXPWu8Eo9RHhoX07wos4y3WuAoAILrGnsJfu-uG1UKfWULdbjUWXWxtAxqA5zPJ6oFUev_FvHGszE118nduzpO7-FCj0ApW9sVOGiIahOTwzDK7zu4mIaHeSIGled6589fnyYl6zKXcOE3H79Efa8Axfksv8eVEDs1mAaIdh-QW1RI0wZbfZt3jlUg15wuIL1xDvKIfOzUOCWbNkAQtZw0ld3cd8aC2GpwPPyW-p5y5ToXvRR5Rq3VM5xEcUzQ2LaqpSXfNPUrOMkntVwntmyKY37n3jCVezlpXKjyS8Y1NzxQMYjigu_Jy7Jf3ikOPV_RLtTfs9xDXSVXtMYOxG9-wEX_cX_DWjPKsWSOT-wsfVMKjtJmoZrs5rni0UpfyV900nJkef6AFUjWP9nfWzRx-33T9fzEr_sjlipMbfohxlOe9w57zYMso02cc74ZISnF1NECQhkLD7IQCTDQbqwG0dvA8srBUhcQ3aj5AZKXZlxLdzmLDq03NudhMqEbb277UlCI1WtEs_CIGqPDHM-COAk9SB1FdPbcThPwgT0Vwjw9oD3h00H_1mr_5yg5UbZ5He4_yw8pJ_ruGUy4npnMEjcxuAPtruIzfxMGQJejptPGoKmLAvuzvbb_MKmGtW1M5iTjAakZG7_0Aj50`,
                        "Content-Type": "multipart/form-data",

                        //"Access-Control-Allow-Origin": "*"

                    },
                });
            //  console.log(response.data,'response.data')
            console.log(response.data)
            return response.data;


        } catch (error) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error.message)
        }
    }
)
/************************ Public Ressources IDs ***********************/

export const fetchPublicRessourcesIDs = createAsyncThunk(
    "files/fetchMeetingTopics",
    async (data, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            const response = await axios.get(`http://localhost/content/public_app/meetings_topics/search`);
            //  console.log(response.data,'response.data')
            //console.log(response.data)
            return response.data;


        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)
/************************ Public Ressources Details ***********************/

export const fetchPublicRessourcesDetails = createAsyncThunk(
    "files/fetchPublicRessourcesDetails",
    async (data, thunkAPI) => {
        console.log(data.id)
        // eslint-disable-next-line no-unused-vars
        const { rejectWithValue } = thunkAPI;
        try {
            const response = await axios.get(`http://localhost/file_handler/get_course_file?file_type=free&file_id=${data.id}`, {
                headers: {
                    Authorization: "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJ0YWxhYmEtaWFtIiwiYXVkIjoidGFsYWJhLWlhbSIsImlzcyI6Imh0dHBzOi8vaWFtLnRhbGFiYS5jb20iLCJleHAiOjE2ODY5MjM3MDYsImlhdCI6MTY2NTMyMzcwNiwiYWNjb3VudF92ZXJpZmllZCI6dHJ1ZSwidXNlcl9waG9uZV9udW1iZXIiOm51bGwsInVzZXJfZW1haWwiOiJtemFoaGRAZ21haWwuY29tIiwiZmlyc3RfbmFtZSI6ImhlZGkiLCJsYXN0X25hbWUiOiJtemFoIiwidXNlcl9pZCI6IjEiLCJhcHBsaWNhdGlvbnMiOlt7ImFwcGxpY2F0aW9uX2lkIjoidGFsYWJhX2FkbWluX2FwcCIsInBlcm1pc3Npb25zIjpbImFkbWluIiwibWFuYWdlciJdfV19.F6wU9ulUs5C4_x4p9s_r0O90XRXPWu8Eo9RHhoX07wos4y3WuAoAILrGnsJfu-uG1UKfWULdbjUWXWxtAxqA5zPJ6oFUev_FvHGszE118nduzpO7-FCj0ApW9sVOGiIahOTwzDK7zu4mIaHeSIGled6589fnyYl6zKXcOE3H79Efa8Axfksv8eVEDs1mAaIdh-QW1RI0wZbfZt3jlUg15wuIL1xDvKIfOzUOCWbNkAQtZw0ld3cd8aC2GpwPPyW-p5y5ToXvRR5Rq3VM5xEcUzQ2LaqpSXfNPUrOMkntVwntmyKY37n3jCVezlpXKjyS8Y1NzxQMYjigu_Jy7Jf3ikOPV_RLtTfs9xDXSVXtMYOxG9-wEX_cX_DWjPKsWSOT-wsfVMKjtJmoZrs5rni0UpfyV900nJkef6AFUjWP9nfWzRx-33T9fzEr_sjlipMbfohxlOe9w57zYMso02cc74ZISnF1NECQhkLD7IQCTDQbqwG0dvA8srBUhcQ3aj5AZKXZlxLdzmLDq03NudhMqEbb277UlCI1WtEs_CIGqPDHM-COAk9SB1FdPbcThPwgT0Vwjw9oD3h00H_1mr_5yg5UbZ5He4_yw8pJ_ruGUy4npnMEjcxuAPtruIzfxMGQJejptPGoKmLAvuzvbb_MKmGtW1M5iTjAakZG7_0Aj50",
                    "Access-Control-Allow-Origin": "*"
                },
            });
            // console.log(response.data, 'response.data')
            return response.data;


        } catch (error) {
            console.log(error)
            //   return rejectWithValue(error.message)
        }
    }
)

const files_handler = createSlice({
    name: "files",
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        //add file

        /* [fileHandler.pending]: (state) => {
            state.loading = true;
            state.error = null
        },
        [fileHandler.fulfilled]: (state, action) => {
            state.loading = false;
            state.record = action.payload;
        },
        [fileHandler.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }, */

        //get ids of files
        builder
            .addCase(fetchPublicRessourcesIDs.pending, (state) => {
                state.status = 'loading';
                state.error = null

            })
            .addCase(fetchPublicRessourcesIDs.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.record = action.payload;
            })
            .addCase(fetchPublicRessourcesIDs.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            //get details of files

            .addCase(fetchPublicRessourcesDetails.pending, (state) => {
                state.status = 'loading';
                state.error = null

            })
            .addCase(fetchPublicRessourcesDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.record = action.payload;
            })
            .addCase(fetchPublicRessourcesDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })


    }
})

export default files_handler.reducer;