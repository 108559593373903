import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Typewriter from "typewriter-effect";
import Lottie from "lottie-react";
import talaba_hero_animation from '../../assets/animation/talaba_hero_animation.json'
import { RoughNotation } from 'react-rough-notation'
import { useTranslation } from 'react-i18next';

const Hero = () => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <>
      <div className="banner-area banner-area-1 go-top" style={{ backgroundColor: "#f1e7fa"}}>
        <div className="container-fluid">
          {
            i18n.language === 'ar' ?
              <div className="row justify-content-center">
                {
                  isMobile
                  ? null
                  : <div className="col-lg-5 col-md-8 order-lg-1 align-self-center">
                    <div className="thumb b-animate-thumb">
                      <Lottie animationData={talaba_hero_animation} style={{ height: "520px"}} />
                    </div>
                  </div>
                }
                <div className="col-lg-5 order-lg-2 align-self-center">
                  <div className="banner-inner text-center text-lg-right mt-5 mt-lg-0">
                    <h2 className="b-animate-1" style={{ fontWeight: "lighter", color: "white" }}>
                      {' '}
                      <RoughNotation
                        key={currentLanguage}
                        animate="true"
                        type="highlight"
                        show={true}
                        color="#9143DA"
                        animationDelay={1000}
                        animationDuration={2500}
                        className="text-slate-200"
                      >
                        {t("title")}&nbsp;
                      </RoughNotation>
                    </h2>
                    <div className="py-3">
                      <h2 className="b-animate-2 title">
                        {t("secondary title")}
                      </h2>
                    </div>
                    <div>
                      <Link 
                        className="btn btn-base b-animate-3 mr-sm-3 mr-2"
                        to="https://app.talabatn.net/authentication/signup"
                        id="service"
                        target="_blank"
                      >
                        {t("start now")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            :
            <div className="row justify-content-center">
              {
                isMobile
                ? null
                : <div className="col-lg-5 col-md-8 order-lg-12 align-self-center">
                  <div className="thumb b-animate-thumb">
                    <Lottie animationData={talaba_hero_animation} style={{ height: "520px"}} />
                  </div>
                </div>
              }
              <div className="col-lg-5 order-lg-1 align-self-center">
                <div className="banner-inner text-center text-lg-left mt-5 mt-lg-0">
                  <h2 className="b-animate-1" style={{ fontWeight: "lighter", color: "white" }}>
                    {' '}
                    <RoughNotation
                      animate="true"
                      type="highlight"
                      show={true}
                      color="#9143DA"
                      animationDelay={1000}
                      animationDuration={2500}
                      className="text-slate-200"
                      padding={[8, 8]}
                    >
                      {t("title")}&nbsp;
                    </RoughNotation>
                  </h2>
                  <div className="py-3">
                    <h2 className="b-animate-2 title">
                      {t("secondary title")}
                    </h2>
                    {
                      isMobile
                      ? null
                      : <h3 className="b-animate-2 pl-3">
                        <div className="row">
                          <span style={{ paddingRight: "10px" }}>{t("typewriter fixed text")}</span>
                          <span style={{ color: "#9B54DD" }}>
                            <Typewriter
                              options={{
                                strings: [`${t('typewriter text one')}`, `${t('typewriter text two')}`, `${t('typewriter text three')}`],
                                autoStart: true,
                                loop: true,
                                delay: 150,
                                pauseFor:4000 }}
                            />
                          </span>
                        </div>
                      </h3>
                    }
                  </div>
                  <div>
                    <Link 
                      className="btn btn-base b-animate-3 mr-sm-3 mr-2"
                      to="https://app.talabatn.net/authentication/signup"
                      id="service"
                      target="_blank"
                    >
                      {t("start now")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <div className="custom-shape-divider-bottom-1704549248">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill" fill="#f1e7fa"></path>
              <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill" fill="#f1e7fa"></path>
              <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill" fill="#f1e7fa"></path>
          </svg>
      </div>
    </>
  );
};

export default Hero;
