import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { publicUrl } from '../../constants/endpoints';
import { podcast } from '../../constants/podcast';
import VideoModal from '../section-components/video-modal';
import { useTranslation } from "react-i18next";

const Podcast = () => {
	const { t, i18n } = useTranslation();
	const [selectedCourse, setSelectedCourse] = useState({})
	const [showModal, setShowModal] = useState(false)
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	useEffect(() => {
		const handleResize = () => {
		setIsMobile(window.innerWidth <= 768);
		};

		window.addEventListener('resize', handleResize);

		return () => {
		window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleOpenModal = (course) => {
		setSelectedCourse(course);
		setShowModal(true);
	}
	const handleCloseModal = () => {
		setShowModal(false);
	}

    return  (
		<>
			<VideoModal isMobile={isMobile} showModal={showModal} handleCloseModal={handleCloseModal} modalHeadTitle={selectedCourse.name} youtubeVideoId={selectedCourse.videoId} />
			<div className="course-single-area pd-top-60 pd-bottom-90">
			  <div className="container">
			  	<div className="row">
					{
						i18n.language === "ar"
						? <>
							<div className="col-md-6"/>
							<div className="col-md-6 text-right">
								<div className="section-title">
									<h6 className="sub-title left-line">{t('opinions and experiences')}</h6>
									<h2 className="title">{t('podcast title')}</h2>
								</div>
							</div>
						</>
						: <div className="col-md-12">
							<div className="section-title">
								<h6 className="sub-title right-line">{t('opinions and experiences')}</h6>
								<h2 className="title">{t('podcast title')}</h2>
							</div>
						</div>
					}
				</div>
			    <div className="row justify-content-center">
					<Swiper
						slidesPerView={1}
						autoplay={{ delay: 2500 }}
						pagination={{ clickable: true }}
						loop={true}
						modules={[Autoplay, Pagination, Navigation]}
						className="mySwiper"
					>
						{
							podcast.map((course, index) => (
								<SwiperSlide
								className={``}
								style={{ cursor: "pointer" }}
								key={index}
								>
									<div className="single-course-inner bg-white" onClick={() => handleOpenModal(course)}>
										<div className="thumb">
											<img style={{ height: `${ isMobile ? "250px" : "650px" }`, objectFit: 'cover'}} src={publicUrl+course.img} alt="img" />
										</div>
									</div>	
								</SwiperSlide>
							))
						}
					</Swiper>
			    </div>
			  </div>
			</div>
		</>
	);
}

export default Podcast