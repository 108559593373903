import React, {useEffect, useState} from 'react';
import Chip from '@mui/material/Chip';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { publicUrl } from '../../constants/endpoints';
import VideoModal from '../section-components/video-modal';
import { useTranslation } from "react-i18next";

const Courses = () => {
	const { t, i18n } = useTranslation();
	const courses = [
		{
			"name": t("Séance de révision théorie du consommateur: Microéconomie"),
			"img": "assets/img/courses/course1.jpg",
			"category": "Talaba",
			"videoId": "d1KbiCQieWU",
			"rating": "62",
		},
		{
			"name": t("Extrait d’une séance de formation algorithmiques et langage C"),
			"img": "assets/img/courses/course2.jpg",
			"category": "Talaba",
			"videoId": "RPz_XtS5J2s",
			"rating": "39",
		},
		{
			"name": t("Extrait d’une séance de révision sur le producteur: Microéconomie"),
			"img": "assets/img/courses/course3.jpg",
			"category": "Talaba",
			"videoId": "_7m4wwLQfrY",
			"rating": "41",
		},
		{
			"name": t("Séance de révision les coûts complets: Gestion"),
			"img": "assets/img/courses/course4.png",
			"category": "Talaba Junior",
			"videoId": "DFDntr2FQQw",
			"rating": "167",
		},
		{
			"name": t("Exercices méthodiques sur l'essai d'histoire"),
			"img": "assets/img/courses/course5.jpg",
			"category": "Talaba Junior",
			"videoId": "G72XZJPjKHI",
			"rating": "31",
		},
		{
			"name": t("Exercice de révision dérivabilité pour bac économie: Mathématiques"),
			"img": "assets/img/courses/course6.png",
			"category": "Talaba Junior",
			"videoId": "nAAiRL7_rSE",
			"rating": "122",
		},
		{
			"name": t("Correction exercice gestion prévisionnelle des stocks: Gestion"),
			"img": "assets/img/courses/course7.jpg",
			"category": "Talaba Junior",
			"videoId": "u3yxnI_dEHY",
			"rating": "64",
		},
		{
			"name": t("La pensée scientifique des Arabes (baccalauréat en économie et gestion)"),
			"img": "assets/img/courses/course8.jpg",
			"category": "Talaba Junior",
			"videoId": "NlI_8NODO40",
			"rating": "36",
		},
		{
			"name": t("Géographie des routes commerciales"),
			"img": "assets/img/courses/course9.jpg",
			"category": "Talaba Junior",
			"videoId": "CGxIDw96t9s",
			"rating": "16",
		}
	];

	const [currentCourses, setCurrentCourses] = useState([])
	const [categories, setCategories] = useState([])
	const [selectedCourse, setSelectedCourse] = useState({})
	const [showModal, setShowModal] = useState(false)
	const [categoryClickedStatus, setCategoryClickedStatus] = useState(
		Array(courses.length).fill(false)
	);

	const handleOpenModal = (course) => {
		setSelectedCourse(course);
		setShowModal(true);
	}
	const handleCloseModal = () => {
		setShowModal(false);
	}

	const getCategories = (courses) => {
		const allCategories = courses.map((course) => course.category);
		const uniqueCategories = [...new Set(allCategories)];
		setCategories(uniqueCategories);
	};	  

	const handleCategory = (category, index) => {
		let filteredCourses;
		let updatedStatus;
		if (category === "All Courses") {
		  filteredCourses = courses;
		  updatedStatus = Array(courses.length).fill(false);
		} else {
		  filteredCourses = courses.filter((course) => course.category === category);
		  updatedStatus = Array(courses.length).fill(false);
		  updatedStatus[index] = true;
		}
		setCategoryClickedStatus(updatedStatus);
		setCurrentCourses(filteredCourses);
	}

	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	useEffect(() => {
		const handleResize = () => {
		setIsMobile(window.innerWidth <= 768);
		};

		window.addEventListener('resize', handleResize);

		return () => {
		window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		getCategories(courses)
		setCurrentCourses(courses)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [i18n.language])

    return  (
		<>
			<VideoModal isMobile={isMobile} showModal={showModal} handleCloseModal={handleCloseModal} modalHeadTitle={selectedCourse.name} youtubeVideoId={selectedCourse.videoId} />
			<div className="course-single-area pd-top-60 pd-bottom-90" style={{ backgroundImage: "url(" + publicUrl + "assets/img/bg/bg.png)" }}>
			  <div className="container">
			  	<div className="row">
					{
						i18n.language === "ar"
						? <>
							<div className="col-md-6"/>
							<div className="col-md-6 text-right">
								<div className="section-title">
									<h6 className="sub-title left-line">{t('content extracts')}</h6>
									<h2 className="title">{t('videos')}</h2>
									<div>
									{
										[
											...categories.map((category, index) => (
											<Chip
												key={index}
												style={{
												backgroundColor: `${categoryClickedStatus[index + 1] ? "#9951DD" : "white"}`,
												color: `${categoryClickedStatus[index + 1] ? "white" : "#000"}`,
												padding: "5px",
												margin: "5px",
												cursor: 'pointer'
												}}
												size="lg"
												variant="solid"
												label={category}
												onClick={() => handleCategory(category, index + 1)}
											/>
											)),
											<Chip
											key="all-courses"
											style={{
												backgroundColor: `${categoryClickedStatus[0] ? "#9951DD" : "white"}`,
												color: `${categoryClickedStatus[0] ? "white" : "#000"}`,
												padding: "5px",
												margin: "5px",
												cursor: 'pointer'
											}}
											size="lg"
											variant="solid"
											label={t('all courses')}
											onClick={() => handleCategory("All Courses", 0)}
											/>
										]
									}
									</div>
								</div>
							</div>
						</>
						:
						<div className="col-md-12">
							<div className="section-title">
								<h6 className="sub-title right-line">{t('content extracts')}</h6>
								<h2 className="title">{t('videos')}</h2>
								<div>
								{
									[
										<Chip
										key="all-courses"
										style={{
											backgroundColor: `${categoryClickedStatus[0] ? "#9951DD" : "white"}`,
											color: `${categoryClickedStatus[0] ? "white" : "#000"}`,
											padding: "5px",
											margin: "5px",
											cursor: 'pointer'
										}}
										size="lg"
										variant="solid"
										label={t('all courses')}
										onClick={() => handleCategory("All Courses", 0)}
										/>,
										...categories.map((category, index) => (
										<Chip
											key={index}
											style={{
											backgroundColor: `${categoryClickedStatus[index + 1] ? "#9951DD" : "white"}`,
											color: `${categoryClickedStatus[index + 1] ? "white" : "#000"}`,
											padding: "5px",
											margin: "5px",
											cursor: 'pointer'
											}}
											size="lg"
											variant="solid"
											label={category}
											onClick={() => handleCategory(category, index + 1)}
										/>
										))
									]
								}
								</div>
							</div>
						</div>
					}
				</div>
			    <div className="row text-center">
					<Swiper
						slidesPerView={isMobile ? 1 : Math.min(3, currentCourses.length)}
						autoplay={{ delay: 2500 }}
						pagination={{ clickable: true }}
						loop={true}
						modules={[Autoplay, Pagination, Navigation]}
						className="mySwiper"
					>
						{
							currentCourses.map((course, index) => (
								<SwiperSlide
								className={`col-lg-${Math.max(12 / Math.min(3, currentCourses.length), 4)} col-md-6`}
								style={{ cursor: "pointer" }}
								key={index}
								>
									<div className="single-course-inner bg-white" onClick={() => handleOpenModal(course)}>
										<div className="thumb">
											<img style={{ height: '200px', objectFit: 'cover'}} src={publicUrl+course.img} alt="img" />
										</div>
										<div className="details">
											<div className="details-inner" style={{ minHeight: "110px" }}>
												<h6>{course.name}</h6>
											</div>
											<div className="emt-course-meta">
												<div className="row">
													<div className="col-3">
														<div className="rating">
															<i className="fa fa-thumbs-up" /> {course.rating}
														</div>
													</div>
													<div className="col-9">
														<div className="price text-right">
															<Chip
																style={{backgroundColor: '#f1f1f1', color: '#000'}}
																size="lg"
																variant="solid"
																label={course.category}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>	
								</SwiperSlide>
							))
						}
					</Swiper>
			    </div>
			  </div>
			</div>
		</>
	);
}

export default Courses