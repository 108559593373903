import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = { record: [], loading: true, error: null }

export const fetchAllTeachers = createAsyncThunk(
    "teacher/fetchAllTeachers",
    async (_, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            const response = await axios.get("http://localhost/content/admin_app/teachers/search", {
                headers: {
                    Authorization: `b eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJ0YWxhYmEtaWFtIiwiYXVkIjoidGFsYWJhLWlhbSIsImlzcyI6Imh0dHBzOi8vaWFtLnRhbGFiYS5jb20iLCJleHAiOjE2ODY5MjM3MDYsImlhdCI6MTY2NTMyMzcwNiwiYWNjb3VudF92ZXJpZmllZCI6dHJ1ZSwidXNlcl9waG9uZV9udW1iZXIiOm51bGwsInVzZXJfZW1haWwiOiJtemFoaGRAZ21haWwuY29tIiwiZmlyc3RfbmFtZSI6ImhlZGkiLCJsYXN0X25hbWUiOiJtemFoIiwidXNlcl9pZCI6IjEiLCJhcHBsaWNhdGlvbnMiOlt7ImFwcGxpY2F0aW9uX2lkIjoidGFsYWJhX2FkbWluX2FwcCIsInBlcm1pc3Npb25zIjpbImFkbWluIiwibWFuYWdlciJdfV19.F6wU9ulUs5C4_x4p9s_r0O90XRXPWu8Eo9RHhoX07wos4y3WuAoAILrGnsJfu-uG1UKfWULdbjUWXWxtAxqA5zPJ6oFUev_FvHGszE118nduzpO7-FCj0ApW9sVOGiIahOTwzDK7zu4mIaHeSIGled6589fnyYl6zKXcOE3H79Efa8Axfksv8eVEDs1mAaIdh-QW1RI0wZbfZt3jlUg15wuIL1xDvKIfOzUOCWbNkAQtZw0ld3cd8aC2GpwPPyW-p5y5ToXvRR5Rq3VM5xEcUzQ2LaqpSXfNPUrOMkntVwntmyKY37n3jCVezlpXKjyS8Y1NzxQMYjigu_Jy7Jf3ikOPV_RLtTfs9xDXSVXtMYOxG9-wEX_cX_DWjPKsWSOT-wsfVMKjtJmoZrs5rni0UpfyV900nJkef6AFUjWP9nfWzRx-33T9fzEr_sjlipMbfohxlOe9w57zYMso02cc74ZISnF1NECQhkLD7IQCTDQbqwG0dvA8srBUhcQ3aj5AZKXZlxLdzmLDq03NudhMqEbb277UlCI1WtEs_CIGqPDHM-COAk9SB1FdPbcThPwgT0Vwjw9oD3h00H_1mr_5yg5UbZ5He4_yw8pJ_ruGUy4npnMEjcxuAPtruIzfxMGQJejptPGoKmLAvuzvbb_MKmGtW1M5iTjAakZG7_0Aj50`,
                },
            });
            //  console.log(response.data,'response.data')
            //console.log(response.data)
            return response.data;


        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const addNewTeacher = createAsyncThunk(
    "teacher/addNewTeacher",
    async (data, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            const response = await axios.post("http://localhost/content/admin_app/teachers", data,
                {
                    headers: {
                        Authorization: `b eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJ0YWxhYmEtaWFtIiwiYXVkIjoidGFsYWJhLWlhbSIsImlzcyI6Imh0dHBzOi8vaWFtLnRhbGFiYS5jb20iLCJleHAiOjE2ODY5MjM3MDYsImlhdCI6MTY2NTMyMzcwNiwiYWNjb3VudF92ZXJpZmllZCI6dHJ1ZSwidXNlcl9waG9uZV9udW1iZXIiOm51bGwsInVzZXJfZW1haWwiOiJtemFoaGRAZ21haWwuY29tIiwiZmlyc3RfbmFtZSI6ImhlZGkiLCJsYXN0X25hbWUiOiJtemFoIiwidXNlcl9pZCI6IjEiLCJhcHBsaWNhdGlvbnMiOlt7ImFwcGxpY2F0aW9uX2lkIjoidGFsYWJhX2FkbWluX2FwcCIsInBlcm1pc3Npb25zIjpbImFkbWluIiwibWFuYWdlciJdfV19.F6wU9ulUs5C4_x4p9s_r0O90XRXPWu8Eo9RHhoX07wos4y3WuAoAILrGnsJfu-uG1UKfWULdbjUWXWxtAxqA5zPJ6oFUev_FvHGszE118nduzpO7-FCj0ApW9sVOGiIahOTwzDK7zu4mIaHeSIGled6589fnyYl6zKXcOE3H79Efa8Axfksv8eVEDs1mAaIdh-QW1RI0wZbfZt3jlUg15wuIL1xDvKIfOzUOCWbNkAQtZw0ld3cd8aC2GpwPPyW-p5y5ToXvRR5Rq3VM5xEcUzQ2LaqpSXfNPUrOMkntVwntmyKY37n3jCVezlpXKjyS8Y1NzxQMYjigu_Jy7Jf3ikOPV_RLtTfs9xDXSVXtMYOxG9-wEX_cX_DWjPKsWSOT-wsfVMKjtJmoZrs5rni0UpfyV900nJkef6AFUjWP9nfWzRx-33T9fzEr_sjlipMbfohxlOe9w57zYMso02cc74ZISnF1NECQhkLD7IQCTDQbqwG0dvA8srBUhcQ3aj5AZKXZlxLdzmLDq03NudhMqEbb277UlCI1WtEs_CIGqPDHM-COAk9SB1FdPbcThPwgT0Vwjw9oD3h00H_1mr_5yg5UbZ5He4_yw8pJ_ruGUy4npnMEjcxuAPtruIzfxMGQJejptPGoKmLAvuzvbb_MKmGtW1M5iTjAakZG7_0Aj50`,
                        'Content-Type': 'application/json',
                        /* Accept: 'application/json' */
                    },
                });
            //  console.log(response.data,'response.data')
            console.log(response.data)
            return response.data;


        } catch (error) {
            /*  if (!error.response) {
                 throw error;
             } */
            return rejectWithValue(error.message)
        }
    }
)

const getAllTeachers = createSlice({
    name: "teachers",
    initialState,
    reducers: {},
    extraReducers: {

        //get all teachers

        [fetchAllTeachers.pending]: (state) => {
            state.loading = true;
            state.error = null
        },
        [fetchAllTeachers.fulfilled]: (state, action) => {
            state.loading = false;
            state.record = action.payload;
        },
        [fetchAllTeachers.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },

        //add new teacher
        [addNewTeacher.pending]: (state) => {
            state.loading = true;
            state.error = null
        },
        [addNewTeacher.fulfilled]: (state, action) => {
            state.loading = false;
            state.record = action.payload;
        },
        [addNewTeacher.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },

    }
})

export default getAllTeachers.reducer;