import React from 'react';
import { Link } from 'react-router-dom';

const PolicyPage = () => {
    const firstIntroduction = "La mission de Talaba TN est de connecter les élèves de la Tunisie inscrits à les écoles secondaires et les étudiants, leurs parents et les professeurs afin d’améliorer la qualité de l’enseignement et d’accompagner les élèves et les étudiants sur la voie de la réussite. Au cœur de cette mission, nous plaçons notre engagement de transparence concernant les données collectées à votre sujet et leur utilisation.La présente Politique de confidentialité s’applique dès lors que vous avez recours à nos offres (décrites ci-dessous)."
    const secondIntroduction = "Nous sommes une plateforme d’enseignement en ligne pour les élèves inscrits à les écoles secondaires et les étudiants. Les utilisateurs ont recours à nos services pour trouver, de manière active ou passive le cours de toutes les matières de leur programme scolaire tunisien. Notre Politique de confidentialité s’applique à tous les membres utilisant nos services."
    return <>
        <div className="about-area pd-top-150">
            <div className="container">
                <div className="about-area-inner">
                    <div className="row">
                        <div className={`col-lg-6 about-animate-right`}>
                            <div className="about-inner-wrap pt-5 pt-lg-3 mt-5 mt-lg-0">
                                <div className="section-title mb-0">
                                    <h3 className="title pb-2">Politique de confidentialité</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className={`col-lg-5 about-animate-right`}>
                            <div className="about-inner-wrap pl-xl-4 pt-5 pt-lg-3 mt-5 mt-lg-0">
                                <div className="section-title mb-0">
                                    <p className="py-2">Date d’entrée en vigueur: 15 février 2024</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="about-inner-wrap pt-5 pt-lg-3 mt-5 mt-lg-0">
                                <div className="section-title mb-0">
                                    <div className='single-intro-inner style-icon-bg'>
                                        <p className="details">
                                            Importance de la protection de votre vie privée
                                        </p>
                                        <p className="details">
                                            {firstIntroduction}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <h4 className="title pb-2">Introduction</h4>
                            <div className="about-inner-wrap pt-5 pt-lg-3 mt-5 mt-lg-0">
                                <div className="section-title mb-0">
                                    <div className='style-icon-bg'>
                                        <p className="details">
                                            {secondIntroduction}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <h5 className="title pb-2">1. Données collectées</h5>
                            <div className="about-inner-wrap pt-5 pt-lg-3 mt-5 mt-lg-0">
                                <div className='pl-2'>
                                    <h5 className="title pb-2">1.1 Données collectées</h5>
                                    <div className='pl-2'>
                                        <h6 className="title pb-1">Inscription</h6>
                                        <div className="section-title mb-0">
                                            <div className='style-icon-bg'>
                                                <p className="details">
                                                    Pour créer un compte, vous devez indiquer votre nom et/ou votre pseudonyme, votre adresse e-mail, votre numéro de téléphone, votre classe, ainsi qu’un mot de passe.
                                                </p>
                                            </div>
                                        </div>
                                        <h6 className="title pb-1">Publication et téléchargement sur la plateforme</h6>
                                        <div className="section-title mb-0">
                                            <div className='style-icon-bg'>
                                                <p className="details">
                                                    Nous collectons des données personnelles auprès de vous lorsque vous en indiquez par exemple quand vous remplissez un formulaire (par exemple, les données démographiques ou les notes prises dans ton établissement scolaire, les métiers de vos parents) ou répondez à une enquête.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className="title pb-2">1.2 Utilisation des Services</h5>
                                    <div className='pl-2'>
                                        <div className="section-title mb-0">
                                            <div className='style-icon-bg'>
                                                <p className="details">
                                                    Nous enregistrons des données d’utilisation lorsque vous accédez à notre site notamment lorsque vous affichez du contenu (par exemple, une vidéo de cours ou un magazine), quand vous effectuez une recherche, quand vous poser une question ou publiez un article dans le forum. Nous utilisons des identifiants de connexion, des informations sur les appareils et des adresses IP pour vous identifier et pour enregistrer votre activité.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <h5 className="title pb-2">2. Utilisation de vos données</h5>
                            <p className="details">
                                Notre utilisation de vos données personnelles dépend des Services auxquels vous avez recours, de la manière dont vous vous en servez et des options définies dans vos préférences. Nous utilisons les données que nous détenons à votre sujet pour fournir et personnaliser nos offres , notamment grâce à des systèmes automatisés et à nos déductions, afin qu’ils soient plus pertinentes et utiles pour vous et pour les autres.
                            </p>
                            <div className="about-inner-wrap pt-5 pt-lg-3 mt-5 mt-lg-0">
                                <div className='pl-2'>
                                    <h5 className="title pb-2">2.1 Communications</h5>
                                    <div className='pl-2'>
                                        <div className="section-title mb-0">
                                            <div className='style-icon-bg'>
                                                <p className="details">
                                                    Nous vous contacterons par e-mail, sur votre téléphone portable, par publications sur le site Internet, par messages sur votre boîte de réception Talaba TN ou par la messagerie SMS. Nous vous enverrons des messages relatifs à la disponibilité de nos offres, à la sécurité ou à d’autres questions concernant nos offres.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className="title pb-2">2.2 Développement de Services et recherche</h5>
                                    <div className='pl-2'>
                                        <div className="section-title mb-0">
                                            <div className='style-icon-bg'>
                                                <p className="details">
                                                    Nous utilisons des données, y compris le feedback du public, pour mener des activités de recherche et de développement liées à nos offres, afin de vous proposer, à vous et à d’autres personnes, une meilleure expérience, plus intuitive et personnalisée, d’augmenter le nombre d’adhésions et d’interactions sur nos services.
                                                    Nous réalisons également des sondages et des enquêtes. Vous n’êtes pas obligé de répondre à ces sondages et enquêtes, et vous pouvez choisir de communiquer ou non certaines informations.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className="title pb-2">2.3 Assistance clientèle</h5>
                                    <div className='pl-2'>
                                        <div className="section-title mb-0">
                                            <div className='style-icon-bg'>
                                                <p className="details">
                                                    Nous utilisons les données pour examiner les réclamations (comme des bugs), pour y répondre et pour les résoudre
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className="title pb-2">2.4 Informations agrégées</h5>
                                    <div className='pl-2'>
                                        <div className="section-title mb-0">
                                            <div className='style-icon-bg'>
                                                <p className="details">
                                                    Nous utilisons vos données pour produire et partager des informations agrégées qui ne permettent pas de vous identifier. Par exemple, nous pouvons utiliser vos données pour générer des statistiques sur nos abonnés.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className="title pb-2">2.5 Sécurité et investigations</h5>
                                    <div className='pl-2'>
                                        <div className="section-title mb-0">
                                            <div className='style-icon-bg'>
                                                <p className="details">
                                                    Nous utilisons vos données en cas de nécessité du point de vue de la sécurité, ou pour enquêter sur des suspicions de fraudes et autres violations des conditions d’utilisation de Talaba TN ou de la présente Politique de confidentialité et/ou sur des tentatives visant à causer du tort à nos abonnés et inscrits.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <h5 className="title pb-2">3. Autres informations importantes</h5>
                            <div className="about-inner-wrap pt-5 pt-lg-3 mt-5 mt-lg-0">
                                <div className='pl-2'>
                                    <h5 className="title pb-2">3.1 Sécurité</h5>
                                    <div className='pl-2'>
                                        <div className="section-title mb-0">
                                            <div className='style-icon-bg'>
                                                <p className="details">
                                                    Nous mettons en œuvre des mesures de sécurité visant à protéger vos données, comme le protocole HTTPS. Nous surveillons régulièrement nos systèmes afin de détecter d’éventuelles vulnérabilités et attaques. Néanmoins, cela ne nous permet pas de garantir la sécurité des informations que vous nous envoyez. Il n’y a aucune garantie que les données ne seront pas accessibles, divulguées, modifiées ou détruites par une vioLation de nos protections de gestion, techniques ou physiques.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className="title pb-2">3.2 Marketing direct et signaux “Do Not Track”</h5>
                                    <div className='pl-2'>
                                        <div className="section-title mb-0">
                                            <div className='style-icon-bg'>
                                                <p className="details">
                                                    Actuellement, nous ne partageons pas de données personnelles avec des tiers dans un objectif de marketing direct sans votre autorisation.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className="title pb-2">3.3 Coordonnées</h5>
                                    <div className='pl-2'>
                                        <div className="section-title mb-0">
                                            <div className='style-icon-bg'>
                                                <p className="details">
                                                    Si vous avez des questions ou des plaintes concernant cette Politique, veuillez contacter Talaba TN en ligne ou envoyer un-email sur contact@talabatn.net.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className="title pb-2">3.4 Services externes</h5>
                                    <div className='pl-2'>
                                        <div className="section-title mb-0">
                                            <div className='style-icon-bg'>
                                                <ul className="go-top">
                                                    <li><Link to="https://www.youtube.com/t/terms">YouTube ToS</Link></li>
                                                    <li><Link to="https://policies.google.com/privacy">Google Privacy policy</Link></li>
                                                    <li><Link to="https://security.google.com/settings/security/permissions">Google security settings</Link></li>
                                                    <li><Link to="https://developers.facebook.com/terms">Facebook Platform Terms</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default PolicyPage

