import React from 'react'; 
import Professors from '../components/global/professors';
import { useTranslation } from "react-i18next";

const ProfessorsPage = () => {
    const { t, i18n } = useTranslation();
    return <>
    <div className='pd-top-150'>
        <div className='container'>
            <div className='about-area-inner'>
                <div className='row'>
                    {
                        i18n.language === "ar"
                        ?
                        <>
                            <div className='col-lg-3'/>
                            <div className='col-lg-9 text-right about-animate-right pb-0'>
                                <div className='about-inner-wrap pt-5 pt-lg-3 mt-5 mt-lg-0'>
                                    <div className='section-title mb-0'>
                                        <h2 className='title pb-2'>{t('our professors')}</h2>
                                        <h3 className='title pb-2'>{t('professors page description')}</h3>
                                        <p className='details'>{t('professors page second description')}</p>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <div className='col-lg-12 about-animate-right pb-0'>
                            <div className='about-inner-wrap pt-5 pt-lg-3 mt-5 mt-lg-0'>
                                <div className='section-title mb-0'>
                                    <h2 className='title pb-2'>{t('our professors')}</h2>
                                    <h3 className='title pb-2'>{t('professors page description')}</h3>
                                    <p className='details'>{t('professors page second description')}</p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
        <Professors />
    </div>
    </>
}

export default ProfessorsPage

