import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import styled, { css } from "styled-components";
import Lottie from "lottie-react";
import horizontal_multi_arrow_right from "../../assets/animation/horizontal_multi_arrow_right.json";
import { publicUrl } from "../../constants/endpoints";
import { useTranslation } from "react-i18next";

const AnimatedItem = styled.div`
  ${({ $animationduration, $inview }) =>
    $inview === "true"
      ? css`
          animation: 1.5s ${$animationduration}s fadeInLeft both;
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}
`;

const HowToRegister = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const { t, i18n } = useTranslation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const steps = [
    {
      id: 1,
      step: t('first step to register'),
      img: "assets/img/howToRegister/tap.png"
    },
    {
      id: 2,
      step: t('second step to register'),
      img: "assets/img/howToRegister/form.png"
    },
    {
      id: 3,
      step: t('third step to register'),
      img: "assets/img/howToRegister/signup.png"
    }
  ]

  const renderSteps = steps.map((items, i) => {
    const isLastStep = i === steps.length - 1;
    const shouldRenderLottie = !isMobile;
    const cardStyle = !isMobile ? "col-lg-3 col-md-6" : "col-md-12";
    return (
      <React.Fragment key={items.id}>
        <AnimatedItem
          $animationduration={0.4 + i * 0.2}
          $inview={inView ? "true" : "false"}
          className={cardStyle}
        >
          <div className="single-intro-inner style-icon-bg bg-gray text-center">
            <div className="thumb">
              <img src={publicUrl + items.img} alt="img" />
              <div className="intro-count">{items.id}</div>
            </div>
            <div className="details">
              <h5>{items.step}</h5>
            </div>
          </div>
        </AnimatedItem>
        {!isLastStep && shouldRenderLottie && (
          <div className="pt-5">
            <Lottie animationData={horizontal_multi_arrow_right} style={{ height: "100px" }} />
          </div>
        )}
      </React.Fragment>
    );
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="work-area pd-top-60 pd-bottom-90 go-top" ref={ref} id="htw">
      <div className="container">
        <div className="section-title">
          <div className="row">
            {
              i18n.language === "ar"
              ? <>
                  <div className="col-lg-6" />
                  <div className="col-lg-6 text-right">
                    <h6  className={`sub-title left-line ${
                        inView ? "pricing-animate-1" : ""
                      }`}>{t('how to register title')}</h6>
                    <h2  className={`title ${inView ? "pricing-animate-2" : ""}`}>{t('how to register subtitle')}</h2>
                  </div>
                </>
              : <div className="col-lg-6 align-self-center">
                  <h6  className={`sub-title right-line ${
                      inView ? "pricing-animate-1" : ""
                    }`}>{t('how to register title')}</h6>
                  <h2  className={`title ${inView ? "pricing-animate-2" : ""}`}>{t('how to register subtitle')}</h2>
                </div>
            }
          </div>
        </div>
        <div className={`row ${ isMobile ? "" : "pl-5"}`}>
          {renderSteps}
        </div>
        <div className="text-center" style={{ paddingTop: "50px" }}>
          <Link 
            className="btn btn-base b-animate-3 mr-sm-3 mr-2"
            to="https://app.talabatn.net/authentication/signup"
            id="service"
            target="_blank"
          >
            {t('join now')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HowToRegister;
