import React, {useState, useEffect} from "react";
import { publicUrl } from "../../constants/endpoints";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InfoModal from "../section-components/info-modal";

const Services = () => {
  const { t, i18n } = useTranslation();
  const services = [
    {
      title: t('first service title'),
      description: t('first service description'),
      icon: "assets/img/intro/7.png",
    },
    {
      title: t('second service title'),
      description: t('second service description'),
      icon: "assets/img/intro/8.png",
    },
    {
      title: t('third service title'),
      description: t('third service description'),
      icon: "assets/img/intro/9.png",
    },
    {
      title: t('fourth service title'),
      description: t('fourth service description'),
      icon: "assets/img/intro/10.png",
    }
  ]
  const [showModal, setShowModal] = useState(false);
  const [currentService, setCurrentService] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: 600,
    bgcolor: "white",
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
  };
  
  const titleStyle = {
    fontSize: '2rem',
    fontWeight: 'lighter',
    marginBottom: '1rem',
    color: '#A95DA9'
  };
  
  const descriptionStyle = {
    fontSize: '1.2rem'
  };

  const handleOpenModal = (service) => {
    setShowModal(true);
    setCurrentService(service);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const titleAr = isMobile
    ? {
        textAlign: "center",
        display: "block",
        margin: "auto",
      }
    : {
        paddingLeft: "910px",
      };
  const titleFr = isMobile
    ? {
        textAlign: "center",
        display: "block",
        margin: "auto",
        color: "#881EC9"
      }
    : {
        color: "#881EC9"
      };
  return (
    <>
      <InfoModal showModal={showModal} handleCloseModal={handleCloseModal} style={style} titleStyle={titleStyle} descriptionStyle={descriptionStyle} currentService={currentService} i18n={i18n} />
      <div className="intro-area pd-top-60 pd-bottom-90" style={{ backgroundImage: "url(" + publicUrl + "assets/img/bg/bg.png)" }}>
        <div className="container">
          {
            i18n.language === "ar"
            ?
            <div className="row pt-2 pb-4 px-3 justify-content-center">
              <div className="col-lg-12 col-md-8" style={{ backgroundColor: "white", width: "100%", borderColor: "#881EC9", borderStyle: "dashed", borderRadius: "10px" }}>
                <div className="section-title pt-2 row">
                  <div style={titleAr}>
                    {
                      isMobile
                      ? null
                      : <span style={{ paddingRight: "10px", color: "#912E91" }}>({t('what do we offer question')})</span>
                    }
                    <h3 className="sub-title bgl-primary m-0" style={{ color: "#881EC9" }}>{t('our services')}</h3>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <h5 className="pt-2 pb-0 text-center" style={{ fontWeight: "lighter" }} >
                      {t('our services description')}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="row pt-2 pb-4 px-3 justify-content-center">
              <div className="col-lg-12 col-md-8" style={{ backgroundColor: "white", width: "100%", borderColor: "#881EC9", borderStyle: "dashed", borderRadius: "10px" }}>
                <div className="section-title pt-2">
                  <h3 className="sub-title bgl-primary m-0" style={titleFr}>{t('our services')}</h3>
                  {
                    isMobile
                    ? null
                    : <span style={{ paddingLeft: "10px", color: "#912E91" }}>({t('what do we offer question')})</span>
                  }
                  <br/>
                  <h5 className="pt-2 pb-0 text-center" style={{ fontWeight: "lighter" }} >
                    {t('our services description')}
                  </h5>
                </div>
              </div>
            </div>
          }
          <div className="row an">
            {
              services.map((service, index) => (
                <div className="item col-md-3 pb-3" key={index} style={{ cursor: "pointer" }} onClick={() => handleOpenModal(service)}>
                  <div className="single-intro-inner style-white bg-base-x text-center">
                    <div className="thumb">
                      <img src={publicUrl + service.icon} alt="img" />
                    </div>
                    <div className="details">
                      <h5>{service.title}</h5>
                    </div>
                    <div className="mt-4 mb-0 d-flex flex-column align-items-center">
                      <p className="mb-0" onClick={() => handleOpenModal(service)}>
                        <span className="mr-2">{t('see more')}</span>
                        <span className="mt-1">
                          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path fill='currentColor' d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
          <div className="text-center p" style={{ paddingTop: "50px" }}>
            <Link
              className="btn btn-base b-animate-3 mr-sm-3 mr-2 px-10"
              to="/services"
              target="_blank"
            >
              {t('see more')}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
