export const podcast = [
    {
        "name": "",
        "img": "assets/img/podcast/podcast.jpg",
        "videoId": "SMixSPwjaZA",
        "rating": "284",
    },
    {
        "name": "",
        "img": "assets/img/podcast/podcast2.jpg",
        "videoId": "4jxvoAlBcAY",
        "rating": "149",
    },
    {
        "name": "",
        "img": "assets/img/podcast/podcast3.jpg",
        "videoId": "8MRiNPeIpDM",
        "rating": "76",
    },
];