import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { publicUrl } from '../../constants/endpoints';
import { Select, MenuItem } from '@mui/material';

const Navbar = ()=> {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const isCurrentPath = (path) => location.pathname === path;
    const glassStyle = {
      background: 'rgba(255, 255, 255, 0.1)',
      backdropFilter: 'blur(10px)',
      border: '1px solid rgba(255, 255, 255, 0.3)',
      borderRadius: '8px',
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px',
      padding: '20px',
      height: '100px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' // Add this line for box shadow
    };
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
      window.localStorage.setItem('talaba_lang', lng);
    };
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    return (
      <div className="navbar-area">
        <div className="navbar-top">
          <div className="container-fluid px-5">
            <div className="row">
              <div className="col-md-8 text-md-left text-center ">
                <ul>
                  <li><p className="text-white ml-4" ><i className="fa fa-map-marker text-white" />{t("address")}</p></li>
                  <li><p className="text-white" ><i className="fa fa-envelope-o text-white" />contact@talabatn.net</p></li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul className="topbar-right text-md-right text-center">
                  <li className="social-area">
                    <a href="https://www.facebook.com/talabatunisie/">
                      <i className="fa fa-facebook text-white" aria-hidden="true" />
                    </a>
                    <a href="https://www.youtube.com/@arbi-kaffela-gestion">
                      <i className="fa fa-youtube text-white" aria-hidden="true" />
                    </a>
                    <a href="https://www.instagram.com/talaba_tn_/">
                      <i className="fa fa-instagram text-white" aria-hidden="true" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {
          i18n.language === 'ar' ?
          <nav className="navbar navbar-area-1 navbar-area navbar-expand-lg navbar-glass-effect" style={glassStyle}>
          <div className="container-fluid nav-container">
            <div className="responsive-mobile-menu">
              <button className="menu toggle-btn d-block d-lg-none" data-target="#talaba_main_menu" aria-expanded="false" aria-label="Toggle navigation">
                <span className="icon-left" />
                <span className="icon-right" />
              </button>
            </div> 
            {
              isMobile
              ? <div className="logo">
              <Link to="/"><img src={publicUrl + "assets/img/talabaLogo.png"} alt="img" /></Link>
            </div>
              : null
            }
            <div className="nav-right-part nav-right-part-desktop">
              <span className="pl-3">
                <Select
                  value={i18n.language}
                  onChange={(e) => changeLanguage(e.target.value)}
                  label="Language"
                  variant='standard'
                  style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
                >
                  <MenuItem value="fr">Français</MenuItem>
                  <MenuItem value="ar">العربية</MenuItem>
                </Select>
              </span>
              <Link className="signin-btn " to="https://app.talabatn.net/authentication/signin" target='_blank'>{t("connexion")}</Link>
              <Link className="btn btn-base signUp" to="https://app.talabatn.net/authentication/signup"  target='_blank'>{t("registre for free")}</Link>
            </div>
            <div className="nav-right-part nav-right-part-mobile">
              <a className="signin-btn" href="https://app.talabatn.net/authentication/signin" rel="noreferrer" target='_blank'>{t("connexion")}</a>
              <a className="btn btn-base" href="https://app.talabatn.net/authentication/signup" rel="noreferrer" target='_blank'>{t("registre")}</a>
            </div>
            <div className="collapse navbar-collapse go-top" style={{ paddingRight: `${isMobile ? "0px" : "80px"}`, textAlign: "right"}}>
              <ul className="navbar-nav menu-open">
                {
                  isMobile
                  ? <>
                    <li className='text-right'><Link style={{ color: `${isCurrentPath('/') ? '#832BD5' : ''}` }} to="/">{t('home')}</Link></li>
                    <li className='text-right'><Link style={{ color: `${isCurrentPath('/about-us') ? '#832BD5' : ''}`}} to='/about-us'>{t('about')} </Link> </li>
                    <li className='text-right'><Link style={{ color: `${isCurrentPath('/services') ? '#832BD5' : ''}`}} to='/services'>{t('services')}</Link></li>
                    <li className='text-right'><Link style={{ color: `${isCurrentPath('/courses') ? '#832BD5' : ''}`}} to='/courses'>{t('our content')}</Link></li>
                    <li className='text-right'><Link style={{ color: `${isCurrentPath('/professors') ? '#832BD5' : ''}`}} to='/professors'>{t('our professors')}</Link></li>
                    <li className="d-block d-sm-none text-right"><a href="https://app.talabatn.net/authentication/signin"  rel="noreferrer" target='_blank'>{t("connexion")}</a></li>
                     <li className="d-block d-sm-none text-right"><a href="https://app.talabatn.net/authentication/signup"  rel="noreferrer" target='_blank'>{t("registre")}</a></li>
                  </>
                  : <>
                    <li><Link style={{ color: `${isCurrentPath('/professors') ? '#832BD5' : ''}`}} to='/professors'>{t('our professors')}</Link></li>
                    <li><Link style={{ color: `${isCurrentPath('/courses') ? '#832BD5' : ''}`}} to='/courses'>{t('our content')}</Link></li>
                    <li><Link style={{ color: `${isCurrentPath('/services') ? '#832BD5' : ''}`}} to='/services'>{t('services')}</Link></li>
                    <li><Link style={{ color: `${isCurrentPath('/about-us') ? '#832BD5' : ''}`}} to='/about-us'>{t('about')} </Link> </li>
                    <li><Link style={{ color: `${isCurrentPath('/') ? '#832BD5' : ''}` }} to="/">{t('home')}</Link></li>
                    <li className="d-block d-sm-none"><a href="https://app.talabatn.net/authentication/signin"  rel="noreferrer" target='_blank'>{t("connexion")}</a></li>
                    <li className="d-block d-sm-none"><a href="https://app.talabatn.net/authentication/signup"  rel="noreferrer" target='_blank'>{t("registre")}</a></li>
                  </>
                }
                <li className="d-block d-sm-none">
                  <Select
                    value={i18n.language}
                    onChange={(e) => changeLanguage(e.target.value)}
                    label="Language"
                    variant='standard'
                    style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', width: '100%'}}
                  >
                    <MenuItem value="fr">Français</MenuItem>
                    <MenuItem value="ar">العربية</MenuItem>
                  </Select>
                </li>
              </ul>
            </div> 
            {
              !isMobile
              ? <div className="logo">
              <Link to="/"><img src={publicUrl + "assets/img/talabaLogo.png"} alt="img" /></Link>
            </div>
              : null
            }
          </div>
        </nav>
          :
          <nav className="navbar navbar-area-1 navbar-area navbar-expand-lg navbar-glass-effect" style={glassStyle}>
            <div className="container-fluid nav-container">
              <div className="responsive-mobile-menu">
                <button className="menu toggle-btn d-block d-lg-none" data-target="#talaba_main_menu" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="icon-left" />
                  <span className="icon-right" />
                </button>
              </div> 
              <div className="logo">
                <Link to="/"><img src={publicUrl + "assets/img/talabaLogo.png"} alt="img" /></Link>
              </div>
              <div className="nav-right-part nav-right-part-mobile">
                <a className="signin-btn" href="https://app.talabatn.net/authentication/signin" rel="noreferrer" target='_blank'>{t("connexion")}</a>
                <a className="btn btn-base" href="https://app.talabatn.net/authentication/signup" rel="noreferrer" target='_blank'>{t("registre")}</a>
              </div>
              <div className="collapse navbar-collapse go-top" style={{ paddingLeft: `${isMobile ? "0px" : "80px"}`, textAlign: "left" }}>
                <ul className="navbar-nav menu-open">
                  <li><Link style={{ color: `${isCurrentPath('/') ? '#832BD5' : ''}` }} to="/">{t('home')}</Link></li>
                  <li><Link style={{ color: `${isCurrentPath('/about-us') ? '#832BD5' : ''}`}} to='/about-us'>{t('about')} </Link> </li>
                  <li><Link style={{ color: `${isCurrentPath('/services') ? '#832BD5' : ''}`}} to='/services'>{t('services')}</Link></li>
                  <li><Link style={{ color: `${isCurrentPath('/courses') ? '#832BD5' : ''}`}} to='/courses'>{t('our content')}</Link></li>
                  <li><Link style={{ color: `${isCurrentPath('/professors') ? '#832BD5' : ''}`}} to='/professors'>{t('our professors')}</Link></li>
                  <li className="d-block d-sm-none"><a href="https://app.talabatn.net/authentication/signin"  rel="noreferrer" target='_blank'>{t("connexion")}</a></li>
                  <li className="d-block d-sm-none"><a href="https://app.talabatn.net/authentication/signup"  rel="noreferrer" target='_blank'>{t("registre")}</a></li>
                  <li className="d-block d-sm-none">
                    <Select
                      value={i18n.language}
                      onChange={(e) => changeLanguage(e.target.value)}
                      label="Language"
                      variant='standard'
                      style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', width: '100%'}}
                    >
                      <MenuItem value="fr">Français</MenuItem>
                      <MenuItem value="ar">العربية</MenuItem>
                    </Select>
                  </li>
                </ul>
              </div> 
              <div className="nav-right-part nav-right-part-desktop">
                <Link className="signin-btn " to="https://app.talabatn.net/authentication/signin" target='_blank'>{t("connexion")}</Link>
                <Link className="btn btn-base signUp" to="https://app.talabatn.net/authentication/signup"  target='_blank'>{t("registre for free")}</Link>
                <span className="pl-3">
                  <Select
                    value={i18n.language}
                    onChange={(e) => changeLanguage(e.target.value)}
                    label="Language"
                    variant='standard'
                    style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
                  >
                    <MenuItem value="fr">Français</MenuItem>
                    <MenuItem value="ar">العربية</MenuItem>
                  </Select>
                </span>
              </div>
            </div>
          </nav>
        }
      </div>
    )
}


export default Navbar