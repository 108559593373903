import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { publicUrl } from "../../constants/endpoints";
import { testimonials } from "../../constants/testimonials";
import { useTranslation } from "react-i18next";

const Testimonials = () => {
  const { t, i18n } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	useEffect(() => {
		const handleResize = () => {
		setIsMobile(window.innerWidth <= 768);
		};

		window.addEventListener('resize', handleResize);

		return () => {
		window.removeEventListener('resize', handleResize);
		};
	}, []);

  return (
    <div
      className="testimonial-area pd-top-60 pd-bottom-90"
      style={{
        backgroundImage: "url(" + publicUrl + "assets/img/bg/bg.png)",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="container">
        <div className="row">
          {
            i18n.language === "ar"
            ? <>
              <div className="col-xl-6 col-lg-5 col-md-6"/>
              <div className="col-xl-6 col-lg-7 col-md-6 text-right">
                <div className="section-title">
                  <h6 className="sub-title left-line">{t('testimonials title')}</h6>
                  <h2 className="title">{t('testimonials subtitle')}</h2>
                </div>
              </div>
            </>
            :
            <div className="col-xl-6 col-lg-7 col-md-12">
              <div className="section-title">
                <h6 className="sub-title right-line">{t('testimonials title')}</h6>
                <h2 className="title">{t('testimonials subtitle')}</h2>
              </div>
            </div>
          }
          <Swiper
            slidesPerView={isMobile ? 1 : 3}
            autoplay={{
              delay: 2500
            }}
            pagination={{
              clickable: true,
            }}
            loop={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            {
              testimonials.map((testimonial, index) => (
                <SwiperSlide className="col-md-4 item" key={index}>
                  <div className="single-testimonial-inner">
                    <p style={{ minHeight: "270px" }}>
                      <span className="testimonial-quote pt-2"><i className="fa fa-quote-right" /></span> <br/>
                      <span style={{ textAlign: "center", display: "block", margin: "auto" }}>{testimonial.quote}</span>
                    </p>
                    <div className="media testimonial-author">
                      <div className="media-left">
                        {
                          testimonial.img === "" 
                            ? <AccountCircleIcon style={{fontSize: "80px", color: "#8621C9" }} />
                            : <img style={{ width: '80px', height: 'auto', objectFit: 'cover', borderRadius: "50%"}} src={publicUrl + testimonial.img} alt="img" />
                        }
                      </div>
                      <div className="media-body align-self-center">
                        <h6>{testimonial.author}</h6>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))
            }
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
