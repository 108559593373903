export const testimonials = [
    {
        "quote": "Bonjour, hier c'était une séance histoire geo super",
        "author": "Mamat Balsouma",
        "img": ""
    },
    {
        "quote": "Merci beaucoup et 3aychekom ena farhana el ktachft el platform mt3kom 3amlin mjhoudkom mana alekher merci une autre fois 😍",
        "author": "Mnaffakh Soumaya",
        "img": ""
    },
    {
        "quote": "Merci beaucoup 3jbtni lhesa ow mhlh lprof",
        "author": "Mayssa Mâ YsSa Nasri",
        "img": ""
    },
    {
        "quote": "Formation behia lezem ay we7ed bac eco yamelha 5atar yist7a9ha barcha f informatique de gestion",
        "author": "Chebbi",
        "img": ""
    },
    {
        "quote": "أساتذة ممتازين، عجبني انهم في بداية الحصة يشحنوا معنويات التلاميذ قادمين علىالباك،يعطيو نصائح وبعد يبداو الحصة،اخلاق عالية، نسمع مع ولدي في الحصص،استاذ التاريخ والجغرافيا مبدع،استاذ الرياضيات زادا،التصرف،الكل ربي يباركلهم",
        "author": "Emna Trabelsi",
        "img": ""
    },
    {
        "quote": "بنتي مشاركه معاكم في التصرف الحقيقة فرحانه برشا وحست بتقدم ملحوظ و خاصة مع سي العربي التصرف تولي تحبه برشا تقديم ممتاز و يوسع بالو سي العربي من أقوى أساتذة التصرف في تونس الله يبارك له إنشاء الله",
        "author": "Naima Gabsi",
        "img": ""
    },
    {
        "quote": "اني أول مرة ندخل نتفرج فاللايف و لحقيقة عجبتني برشا الطريقة اللي قاعد تفهم بيها و فهمت كل شي برغم دخلت مخر اما و اللّه استفدت برشا من السيري حبيت نقلك عيشك و يرحم والديك 🩵",
        "author": "As Ma",
        "img": ""

    },
    {
        "quote": "نحب بشكركم بلحق على وقفتكم معانا ❤️❤️ عيشكم يا رب ما نتضيعلناش تعب و فرحة  يا رب❤️",
        "author": "Ayda Amri",
        "img": ""
    }
];